.run-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95%;
}

.run-card {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    max-width: 600px;
    max-height: 500px;
}

.run-container .upper-half {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    height: 40%;
}

.run-container .lower-half {
    flex: 1;
    padding: 10px;
    height: 60%;
    max-height: 52vh;
    overflow-y: auto;
    width: 100%;
}

.run-container .p-card .p-card-body {
    padding: 0.5rem;
    background-color: whitesmoke;
}

.run-container .p-card .p-card-content {
    padding: 0;
}

.form-layout {
    display: flex;
    flex-wrap: wrap;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.grid-item {
    width: 100%;
}

.form-field {
    margin-bottom: 20px;
}

.run-container .button-section {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
    margin-left: 15px;
    margin-left: 15px;
    font-size: 8px;
    max-height: 30px;
    max-width: 300px;
}

.run-container .button-class {
    width: 100%;
}

.dropdown-section {
    margin-bottom: 10px;
    margin-left: 15px;
}

.run-container label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
}

.run-container .p-inputtext1, 
.run-container .p-dropdown1, 
.run-container .p-autocomplete1 {
    width: 100%;
    align-items: center;
}

.run-container .run-card .dropdown-section .p-dropdown, .p-multiselect, .p-inputtext1 {
    width: 300px;
    max-width: 250px; 
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

