.prediction-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    padding: 10px;
}

.headertitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headertitle h3{
    margin: 0;
}

.toggle-graph-button {
    padding: 6px 12px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-size: 12px;
    max-height: 30px;
    width: 100px;
}

.toggle-graph-button:hover {
    background-color: #e0e0e0;
}

.input-container {
    padding: 20px;
}

.input-container .p-field {
    flex: 1 0 33%;
    margin-right: 10px;
}

.input-container .p-field label {
    margin-bottom: 8px;
    font-weight: bold;
}

.input-container .p-field .p-inputnumber {
    width: 30%;
    padding: 6px;
}

.prediction-container .chart canvas {
    height: 250px;
    /* Adjust the height for both bar and scatter charts */
    max-width: 250px;
}