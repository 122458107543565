.model-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95%;
}

.model-card {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    max-width: 600px;
    max-height: 500px;
}

.model-container .upper-half {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    height: 40%;
}

.model-container .lower-half {
    flex: 1;
    padding: 10px;
    height: 40%;
    max-height: 35vh;
    overflow-y: auto;
    width: 100%;
}

.model-container .p-card .p-card-body {
    padding: 0.5rem;
    background-color: whitesmoke;
}

.model-container .p-card .p-card-content {
    padding: 0;
}

.form-layout {
    display: flex;
    flex-wrap: wrap;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.grid-item {
    width: 100%;
}

.form-field {
    margin-bottom: 20px;
}

.model-container .button-section {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
    margin-left: 15px;
    margin-left: 15px;
    font-size: 8px;
    max-height: 30px;
    max-width: 300px;
}

.model-container .button-class {
    width: 100%;
}

.dropdown-section {
    margin-bottom: 10px;
    margin-left: 15px;
}

.model-container label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
}

.model-container .p-inputtext1, 
.model-container .p-dropdown1, 
.model-container .p-autocomplete1,
.model-container .p-multiselect1 {
    width: 100%;
    height: 30px;
    align-items: center;
}

.dropdown-section .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.2rem 0.3rem;
    margin-right: 0.5rem;
    background: #dee2e6;
    color: #495057;
    border-radius: 16px;
    font-size: small;
}

.model-container .model-card .dropdown-section .p-dropdown, .p-multiselect, .p-inputtext1 {
    width: 300px;
    max-width: 250px; 
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

