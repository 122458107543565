.result-container {
    display: flex;
    flex-direction: column;
    height: 100%; 
}

.result-container .upper-half {
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
    height: 28%;
    max-height: 25vh;
}

.result-container .lower-half {
    flex: 1;
    width: 100%;
    height: 50%;
    padding: 10px;
    max-height: 47vh; 
    overflow-x: auto;
    overflow-y: auto;
}


.result-container .datatable-styles .p-datatable {
    font-size: 12px; 
    margin-top: 0;
}

.result-container .p-datatable-smaller .p-datatable-thead > tr > th,
.p-datatable-smaller .p-datatable-tbody > tr > td {
    padding: 0.5rem;
    font-size: 14px;
    padding: 0.5rem;

}

.result-container .tab-content {
    padding: 20px;
    height: calc(100% - 40px); 
    overflow-y: auto; 
}

.result-container .paginator {
    margin-top: 10px; 
}

.result-container .p-paginator {
    display: flex;
    justify-content: center; 
    margin-top: 10px; 
}

.result-container .p-paginator .p-paginator-pages .p-paginator-page {
    padding: 4px 6px; 
    font-size: 12px; 
}

.result-container .row-paginator .p-paginator .p-paginator-prev,
.result-container .row-paginator .p-paginator .p-paginator-next,
.result-container .row-paginator .p-paginator .p-paginator-first,
.result-container .row-paginator .p-paginator .p-paginator-last {
    padding: 4px 6px;
    font-size: 14px;
}

.response-clickable {
    cursor: pointer;
}

.response-clickable:hover {
    text-decoration: underline;
}

.result-button {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.result-button .p-button {
    margin-right: 10px; 
}

.result-button .p-button:last-child {
    margin-right: 0; 
}

.status-active {
    color: yellowgreen;
    font-weight: bold;
}

.status-complete {
    color: green;
    font-weight: bold;
}

.status-failed {
    color: red;
    font-weight: bold;
}

.uppercase-deploy-status {
    text-transform: uppercase;
    color: green; 
    font-weight: bold;
}