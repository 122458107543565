.toolbar {
    background-color: #0db2ea;
    color: #f2f4f5;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    
}

.toolbar h1 {
    margin: 0;
    
}

.logo {
    height: 40px;
    margin-left: 10px;
    
}

.right-section {
    display: flex;
    align-items: center;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-info p {
    margin: 0;
    font-size: 14px;
    margin-left: 4px; 
}

.user-info a {
    margin: 0;
    font-size: 14px;
    margin-left: 2px; 
    color: #f2f4f5;
}

.user-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #f2f4f5;
}

.settings-button {
    margin-right: 8px;
    background-color: #f2f4f5;
    font-size: 14px; 
    width: 30px; 
    height: 30px; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; 
    padding: 0; 
}
