.footer {
    background-color: whitesmoke;
    color:grey;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    padding: 20px;
    border-top: 0.5px ridge rgb(245, 243, 243);
}
