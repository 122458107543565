.report-container {
    padding: 1rem;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
}

.report-header {
    text-align: center;
    margin-bottom: 1rem;
}

.report-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.report-header p {
    margin: 0.5rem 0;
    color: #666;
    font-size: 1rem;
}

.report-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    gap: 1.5rem;
}

.statistics-section {
    margin-bottom: 2rem;
}

.statistics-cards {
    display: flex;
    justify-content: space-around;
    gap: 1.5rem;
}

.stat-card {
    background-color: #ffffff;
    padding: 0.5rem;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1;
}

.stat-card h4 {
    margin: 0;
    font-size: 1rem;
    color: #333;
}

.stat-card p {
    margin: 0.5rem 0;
    font-size: 1rem;
    color: #000;
}

.chart-section {
    margin-bottom: 1rem;
}

.report-container h3 {
    margin: 0;
    font-size: 1rem;
    color: #333;
    margin-bottom: 1rem;
}

.chart-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    overflow-x: auto; /* Enable horizontal scrolling if necessary */
    max-height: 250px;
}
