.user-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.user-management-upper {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    height: 40%;
    max-height: 45vh;
}

.user-management-lower {
    flex: 1;
    padding: 10px;
    height: 40%;
    max-height: 35vh;
    overflow-y: auto;
    width: 100%;
}

.user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: rgb(73, 80, 87);
}

.dialog-box {
    width: 30vw;
}

.form-container {
    margin-bottom: 20px;
}

.form-field {
    margin-bottom: 15px;
}

.form-field label {
    display: block;
    margin-bottom: 5px;
}

.user-table {
    margin-top: 20px;
}

.p-button-danger {
    background-color: #d9534f;
    border-color: #d9534f;
}

.p-error {
    color: #d9534f;
}

.form-field .input-field,
.form-field .p-dropdown1,
.form-field .p-multiselect1 {
    width: 100%;
    height: 30px;
    align-items: center;
    background-color: aliceblue;
}

.p-icon-field-right > .p-inputtext {
    padding-right: 2rem;
    height: 30px;
    width: calc(100vh - 270px);
    background-color: aliceblue;
}

.user-container .p-datatable {
    font-size: 14px;
    margin-top: 0;
}

.user-container .p-datatable thead th {
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
    text-align: left;
    padding: 0.3rem;
}

.user-container .p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 0.2rem;
}

.user-container .p-datatable-smaller .p-datatable-thead>tr>th,
.user-container .p-datatable-smaller .p-datatable-tbody>tr>td {
    font-size: 18px;
    padding: 0.3rem;

}

.user-container .p-datatable tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.user-container .p-datatable tbody tr:hover {
    background-color: #e0e0e0;
}