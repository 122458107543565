.plot-container {
    display: flex;
    flex-direction: row;
    gap: 20px; 
    height: 100%;
    overflow: visible;
}

.plot-container .chart-container {
    margin-bottom: 20px; 
    height: 100%; /* Adjust the height as needed for consistent display */
}

.section {
    background-color: whitesmoke;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 50%;
}

.summary-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.summary-item {
    display: flex;
    justify-content: space-between;
}

.summary-label {
    font-weight: bold;
}

.summary-value {
    color: #333;
}

.section-title {
    font-size: 14px;
    color: #333;
    margin: 0; /* Remove top and bottom margin */
}

.plot-container .bar-chart canvas {
    height: 250px; /* Adjust the height for both bar and scatter charts */
}

.plot-container .chart canvas {
    height: 250px; /* Adjust the height for both bar and scatter charts */
}