.file-details-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.file-details-content .upper-half {
    flex: 1;
    overflow-y: auto;
    padding: 5px;
    height: 50%;
    max-height: 45vh; 
}

.file-details-content .lower-half {
    flex: 1;
    padding: 5px;
    height: 40%;
    max-height: 28vh; 
    overflow-y: auto;
}

.file-details-content .title {
    font-size: 18px; 
    margin: 0;
}

.file-details-content .p-datatable-smaller .p-datatable-thead > tr > th,
.file-details-content .p-datatable-smaller .p-datatable-tbody > tr > td {
    padding: 0.5rem;
    font-size: 14px;
    padding: 0.5rem;

}

.file-list {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.file-list ul {
    list-style-type: none;
    padding: 0;
}

.file-list li {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
}

.file-list h3 {
    margin-top: 0;
}

.history-section {
    margin-top: 20px;
}

.file-details-content .p-datatable {
    margin-top: 20px;
}
