.data-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.data-container .datatable-styles .p-datatable {
    font-size: 25px; 
}

.data-container .p-datatable-smaller .p-datatable-thead > tr > th,
.p-datatable-smaller .p-datatable-tbody > tr > td {
    padding: 0.5rem; 
}

.tabview-container {
    width: 100%; 
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
}

.tabview-header {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    background-color: #089bcc;
    color: white;
}

.tabview-header .p-tabview-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

/* .tabview-panel {
    height: 100%;
    overflow-y: auto; 
    padding: 10px;
    box-sizing: border-box;
}

.tabview-panel h2 {
    margin-top: 0;
} */

.upload-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ccc;
    background-color: #ffffff;
}

.history-section {
    padding: 20px;
    overflow-y: auto;
    background-color: #ffffff;
}

.history-section h2 {
    text-align: left;
    margin-bottom: 20px;
}

.file-upload-section {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.file-upload-button {
    width: 100%;
    max-width: 400px;
}

.s3-data-container {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
}

.s3-item {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.s3-item strong {
    color: #333;
}

.file-detail-container {
    padding: 20px;
}

.main-content {
    margin-left: 120px; 
    padding: 20px;
}

.p-toast {
    z-index: 10000;
}

.upload-section {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.history-section {
    padding: 20px;
    overflow-y: auto;
    background-color: #ffffff;
}

.history-section h2 {
    text-align: left;
    margin-bottom: 20px;
}

