/* portal.css */
.app-container {
    background-color: #f2f4f5;
    display: flex;
    flex-direction: column;
    height: 100vh;
}
/* 
.toolbar {
    background-color: #282c34;
    color: #f2f4f5;
    padding: 10px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
} */

.main-container {
    display: flex;
    background-color: #f2f4f5;
    flex: 1;
    margin-top: 0px; 
}

/* .sidebar {
    width: 100px;
    background-color: #282c34;
    padding: 10px;
    position: fixed;
    top: 0px; 
    bottom: 0;
    z-index: 10;    
} */

.content {
    margin-left: 180px; 
    background-color: #f2f4f5;
    flex: 1;
    padding: 0px;
    position: relative;
    z-index: 1;
    margin-top: 0px; 
    overflow-y: auto; 
    /* border: 0.5px ridge rgb(245, 243, 243); */
}

/* .footer {
    background-color: #636d83;
    color:#f2f4f5;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    padding: 20px;
    border: 0.5px ridge rgb(245, 243, 243);
} */
