.csv-data-table-container {
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    max-height: 50px;
}

.csv-data-table {
    overflow-x: auto;
    max-width: 100%;
}

.toggle-columns-button {
    padding: 6px 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-size: 12px;
    max-height: 30px;
    width: 200px;
}

.toggle-columns-button:hover {
    background-color: #e0e0e0;
}

.row-paginator {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    width: 100%;
    max-height: 30px;
}

.row-paginator .p-paginator {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-height: 30px;
}

.row-paginator .p-paginator .p-paginator-pages {
    padding: 0;
}

.row-paginator .p-paginator .p-paginator-page {
    font-size: 12px;
}

.csv-data-table-container .row-paginator .p-paginator .p-paginator-prev,
.csv-data-table-container .row-paginator .p-paginator .p-paginator-next,
.csv-data-table-container .row-paginator .p-paginator .p-paginator-first,
.csv-data-table-container .row-paginator .p-paginator .p-paginator-last {
    max-height: 20px;
    margin: 0;
}

.csv-data-table-container .p-paginator .p-paginator-pages .p-paginator-page {
    min-width: none;
    height: 0%;
}

.csv-data-table-container .p-paginator .p-dropdown {
    height: 20px;
    margin: 0;
}

.csv-data-table-container .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
    padding: 1px;
}

.csv-data-table-container .p-datatable .p-datatable-thead>tr>th,
.p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    padding: 0.5rem 1rem;
    font-size: 14px;
}

.csv-data-table-container .csv-data-table .p-datatable {
    margin-top: 5px;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.table-header .p-input-icon-left {
    display: flex;
    align-items: center;
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    position: relative;
}

.p-column-filter-menu-button.active-filter-icon,
.p-column-filter-clear-button.active-filter-icon {
    color: #007bff; /* Blue color for active filter */
}

/* Optionally, you might want to adjust other properties to highlight the icon */
.p-column-filter-menu-button.active-filter-icon::before,
.p-column-filter-clear-button.active-filter-icon::before {
    content: ' ';
    position: absolute;
    border-radius: 50%;
    background: rgba(0, 123, 255, 0.2); /* Light blue background */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}
