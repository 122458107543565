/* tabLayout.css */
.tabview-nav li {
  margin: 0 10px;
}

.p-tabview-panels {
  background: #ffffff;
  border: 0 none;
  color: #495057;
  flex: 1;
  padding: 0px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 100%;
  overflow: hidden;
}
.p-tabview {
  height: 100%;
  display: flex;
  flex-direction: column;
}
